// components/Sidebar.tsx
import React, { CSSProperties, ReactNode, useEffect } from 'react';
import WsStaticImage from '../WsStaticImage';
import styles from './style.module.scss';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  direction?: 'left' | 'right' | 'bottom'; // Define direction prop
  type?: 'overview' | 'menu'; // Define direction prop
  className?: string; // Define className prop
  style?: CSSProperties; // Define style prop
  title?: any;
  mainSideBarClass?: any;
  isClearClass?: string;
  isClear?: boolean;
  link?: { title: string; href: string };
  noPad?: boolean;
  up?: boolean;
  handleClear?: () => void; // Define style prop
}
const WsSidebar: React.FC<SidebarProps> = ({
  isOpen,
  onClose,
  children,
  direction = 'right',
  className,
  style,
  title,
  mainSideBarClass = '',
  isClear = false,
  link = { title: '', href: '' },
  isClearClass = '',
  noPad = false,
  up = false,
  handleClear,
}) => {
  let customModalClass = styles[mainSideBarClass];
  if (typeof mainSideBarClass != 'string') {
    mainSideBarClass.forEach((classItem: string) => {
      customModalClass += ' ' + styles[classItem];
    });
  }

  const sidebarClass = isOpen
    ? `${styles.sidebar} ${styles.open} ${styles[direction]} ${customModalClass} ${className || ''} ${up ? styles.up : ''}`
    : `${styles.sidebar} ${styles[direction]} ${className || ''}`;

  useEffect(() => {
    if (isOpen) {
      document.body.className += ' overflow-add';
    } else {
      document.body.className = document.body.className.replace(
        'overflow-add',
        ''
      );
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`${styles.overlay} ${isOpen ? styles.open : ''}`}
        onClick={onClose}
      />

      <div className={sidebarClass} style={style}>
        {direction !== 'bottom' && (
          <button
            type="button"
            className={styles.closeButton}
            onClick={onClose}
          >
            <WsStaticImage
              src={'/images/category/modal-close-img.svg'}
              alt="modal-close-img"
              width={16}
              height={16}
              classNameCls={'lazy-loading'}
            />
          </button>
        )}
        {title && (
          <div
            className={`${styles['slider-head']} ${!noPad ? '' : styles['pad-none']}`}
          >
            <div className={styles.title}>{title ? title : ''}</div>

            {isClear && (
              <span
                className={isClearClass ? styles[isClearClass] : ''}
                onClick={handleClear}
              >
                Clear All
              </span>
            )}
            {link && (
              <a href={link.href} className={styles.link}>
                {link.title}
              </a>
            )}
          </div>
        )}{' '}
        {/* Render the title */}
        <div
          className={`${styles['sidebar-details']} ${!noPad ? '' : styles['pad-none']}`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default WsSidebar;
